<!-- API接口信息维护 -->
<template>
  <div>
    <div>
      <div class="tool">
        <div>
          <a-input-search
            v-model:value="searchParam.searchValue"
            placeholder="请输入关键词"
            enter-button
            @search="onSearch"
          />
        </div>
        <div>
          <a-button type="primary" @click.stop="doAddInterface">
            <PlusOutlined />添加API接口
          </a-button>
        </div>
      </div>
    </div>
    <div style="margin-top: 6px">
      <a-table
        :scroll="{ x: 1000 }"
        :rowKey="(record) => record.id"
        :pagination="false"
        class="ant-table-striped"
        size="middle"
        :loading="loading"
        bordered
        :columns="columns"
        :data-source="interfaceList"
        :rowClassName="
          (record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
      >
        <template #action="{ record }">
          <span>
            <a-button
              type="link"
              size="small"
              @click="doDeleteInterface(record)"
            >
              <SvgIcon iconName="delete_red" className="svg15" />
              <span style="color: red">删除</span>
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button type="link" size="small" @click="doEditInterface(record)">
              <EditOutlined />编辑
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button
              type="link"
              size="small"
              @click="doDetailInterface(record)"
            >
              <FileTextOutlined />详情
            </a-button>
            <a-divider type="vertical" style="border-color: #7cb305" dashed />
            <a-button type="link" size="small" @click="doTestInterface(record)">
              <BugOutlined />测试
            </a-button>
          </span>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="pagination.pageIndex"
          :pageSize="pagination.pageSize"
          :total="pagination.total"
          :page-size-options="pagination.pageSizeOptions"
          :showTotal="(total) => `共 ${total} 条记录`"
          show-size-changer
          @change="onChangePage"
          @showSizeChange="onShowSizeChange"
        >
          <template #buildOptionText="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div>
      <InterfaceForm
        :modalVisible="modalVisible"
        :isCreate="isCreate"
        :searchParam="searchParam"
        :editData="editData"
        @handleModalVisible="handleModalVisible"
        @saveCallBack="saveCallBack"
      />
      <InterfaceTest
        :modalVisible="modalTestVisible"
        :currentInfo="templateInfo"
        @handleTestModalVisible="handleTestModalVisible"
      />
    </div>
  </div>
</template>
<script>
import {
  PlusOutlined,
  EditOutlined,
  BugOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, onMounted, defineComponent, createVNode } from "vue";
import {
  getInterfaceListPage,
  deleteInterface,
} from "@/api/application/interface";
import moment from "moment";
import InterfaceForm from "./components/InterfaceForm.vue";
import InterfaceTest from "./components/InterfaceTest.vue";
import { message, Modal } from "ant-design-vue";
import SvgIcon from "@/components/Svgicon";
export default defineComponent({
  name: "Interface",
  components: {
    PlusOutlined,
    EditOutlined,
    BugOutlined,
    FileTextOutlined,
    InterfaceForm,
    InterfaceTest,
    SvgIcon,
  },
  setup() {
    const state = reactive({
      loading: false,
      isCreate: 0, //0新增  1编辑  2详情
      editData: {
        id: "",
        apiName: "",
        apiCode: "",
        apiUrl: "",
        apiMode: "",
        apiDescribe: "",
        apiCall: "",
        apiReturn: "",
      },
      modalVisible: false,
      searchParam: {
        searchValue: "",
        pageIndex: 1,
        pageSize: 10,
      },
      interfaceList: [],
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 10, // 默认每页显示数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        total: 0, //总条数
      },
    });
    const columns = [
      {
        title: "接口名称",
        dataIndex: "apiName",
        key: "apiName",
        fixed: "left",
        width: 150,
      },
      {
        title: "接口编码",
        dataIndex: "apiCode",
        key: "apiCode",
        width: 150,
      },
      {
        title: "接口地址",
        dataIndex: "apiUrl",
        key: "apiUrl",
        width: 200,
      },
      {
        title: "接口描述",
        dataIndex: "apiDescribe",
        key: "apiDescribe",
        ellipsis: true,
        width: 150,
      },
      {
        title: "接口模式",
        dataIndex: "apiModeInfo.item",
        key: "apiModeName",
        width: 150,
      },
      {
        title: "接口状态",
        dataIndex: "interfaceStatusInfo.item",
        key: "interfaceStatus",
        ellipsis: true,
        width: 150,
      },
      {
        title: "创建时间",
        dataIndex: "createDate",
        key: "createDate",
        align: "center",
        width: 150,
        customRender: ({ text }) => {
          return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
        },
      },
      {
        title: "操作",
        align: "center",
        key: "action",
        width: 400,
        fixed: "right",
        slots: {
          customRender: "action",
        },
      },
    ];
    onMounted(() => {
      initInterfaceList(state.searchParam);
    });
    const initInterfaceList = (searchParam) => {
      state.loading = true;
      getInterfaceListPage(searchParam).then((resp) => {
        if (resp) {
          state.interfaceList = resp.obj.list;
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const onSearch = () => {
      (state.searchParam.pageIndex = 1),
        (state.searchParam.pageSize = 10),
        initInterfaceList(state.searchParam);
    };
    const onChangePage = (page, pageSize) => {
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initInterfaceList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initInterfaceList(state.searchParam);
    };
    const handleModalVisible = (flag) => {
      state.modalVisible = flag;
    };
    const doDeleteInterface = (record) => {
      Modal.confirm({
        title: "温馨提示",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        content: createVNode(
          "div",
          { style: "color:red;" },
          "此操作将永久删除该API接口，是否继续？"
        ),
        onOk() {
          deleteInterface({ id: record.id, logic: true }).then((resp) => {
            if (resp) {
              initInterfaceList(state.searchParam);
            }
          });
        },
        onCancel() {
          message.info("已取消删除API接口操作！！！");
        },
      });
    };
    const doAddInterface = () => {
      handleModalVisible(true);
      state.isCreate = 0;
    };
    const doEditInterface = (record) => {
      handleModalVisible(true);
      state.editData = {
        id: record.id,
        apiName: record.apiName,
        apiCode: record.apiCode,
        apiUrl: record.apiUrl,
        apiMode: record.apiMode,
        apiDescribe: record.apiDescribe,
        apiCall: record.apiCall,
        apiReturn: record.apiReturn,
      };
      state.isCreate = 1;
    };
    const doDetailInterface = (record) => {
      handleModalVisible(true);
      state.editData = {
        id: record.id,
        apiName: record.apiName,
        apiCode: record.apiCode,
        apiUrl: record.apiUrl,
        apiMode: record.apiMode,
        apiDescribe: record.apiDescribe,
        apiCall: record.apiCall,
        apiReturn: record.apiReturn,
      };
      state.isCreate = 2;
    };
    const saveCallBack = (code) => {
      if (code == 200) {
        initInterfaceList(state.searchParam);
      }
    };
    const refData = toRefs(state);
    return {
      ...refData,
      columns,
      initInterfaceList,
      onSearch,
      onChangePage,
      onShowSizeChange,
      handleModalVisible,
      doDeleteInterface,
      doAddInterface,
      doEditInterface,
      doDetailInterface,
      saveCallBack,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}

.ant-input-search {
  width: 300px;
  margin-right: 5px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>
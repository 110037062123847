import { getRequest, postRequest, putRequest, deleteRequest } from '../request'

export const getInterfaceList = (res) => {
    return getRequest('/interface/interfaces', res)
}

export const getInterfaceListPage = (res) => {
    return postRequest('/interface/interfaces', res)
}

export const saveInterface = (res) => {
    if (res.id) {
        //更新API接口
        return putRequest('/interface/interface', res);
    }
    else {
        //添加API接口
        return postRequest('/interface/interface', res);
    }
}

export const deleteInterface = (res) => {
    return deleteRequest('/interface/interface/' + res.id + "?logic=" + res.logic)
}

export const getInterfaceHistoryListPage = (res) => {
    return postRequest('/interface/interfaces/his', res)
}